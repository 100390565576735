<template>
	<v-container fill-height fixed>
		<v-layout justify-center wrap>
			<Progress :loading="loading" type="overlay"></Progress>
			<material-card color="info" title="Реферальная система" v-if="referral || error">
				<v-container>
					<v-layout wrap>
						<v-flex xs12>
							<v-card>
								<v-card-text>
									Суть реферальной системы заключается в том, что вы будете получать 10% от всех платежей, заплаченных рефералом и 5% от всех его рефералов.
									Для этого реферал, при регистрации, должен ввести промокод, при этом он получит 6 дней подписки.
									<v-alert type="error" class="mt-5" v-if="error">{{ error }}</v-alert>
									<v-alert type="info" class="mt-5" v-else>
										Ваш промокод: {{ referral.promo }}
										<br />
										Можете просто дать ссылку: https://atoto.ru/login/{{ referral.promo }}
										<v-btn outlined small @click="copyToClipboard">Скопировать в буфер</v-btn>
										<input type="hidden" id="url" :value="`https://atoto.ru/login/${referral.promo}`" />
										<div v-if="referral.child_count" class="pt-2">
											У вас сейчас {{ referral.child_count }} {{ countPlural(referral.child_count, ['реферал', 'реферала', 'рефералов']) }}.
											Вам уже перечислено от них {{ referral.earn }} бонусных рублей.
										</div>
									</v-alert>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
			</material-card>
		</v-layout>
		<v-snackbar v-model="copySuccess" color="success" :timeout="5000">Ссылка скопирована</v-snackbar>
	</v-container>
</template>

<script>
import { Helper } from "../../mixins/Helper";

function fetchData() {
	return window.fetchData("/api/user/get-referral/", {});
}

export default {
	mixins: [Helper],
	data() {
		return {
			loading: true,
			error: null,
			referral: null,
			copySuccess: false
		};
	},
	created() {
		document.title = "Реферальная система";
		this.fetchData();
	},
	watch: {},
	methods: {
		async fetchData() {
			let t = this;
			t.error = t.person = null;
			t.loading = true;

			let data = await fetchData();
			t.loading = false;
			if (data.error) t.error = data.error;
			else t.referral = data.referral;
		},
		copyToClipboard() {
			let el = document.querySelector("#url");
			el.setAttribute("type", "text");
			el.select();

			try {
				document.execCommand("copy");
				this.copySuccess = true;
			} catch (err) {
				alert(
					"Извините, браузер не позволил скопировать ссылку, скопируйте её вручную"
				);
			}

			/* unselect the range */
			el.setAttribute("type", "hidden");
			window.getSelection().removeAllRanges();
		}
	}
};
</script>
<style scoped>
</style>